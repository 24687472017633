<template>
  <ul class="card-header" style="list-style-type: none; padding-left: 5%">
    Register
    <li v-for="reg in registers" :key="reg" class="clearfix" style="display: flex; align-items: center;">
      <!-- stars -->
      <i
          class="bi"
          :class="isRegToRead(reg.register) ? 'bi-star-fill' : 'bi-star'"
          style="cursor: pointer; margin-right: 10px; font-size: .75rem;"
          title="Set default register"
          @click="setRegToRead(reg.register)"
      ></i>
      <button
          @click="readRegister(reg.register)"
          :class="{ active: selectedReg(reg.register) }"
          style="flex-grow: 1;"
      >
        <div v-if="reg.name" class="register-row">
          <span class="fa-pull-left">Name:</span>
          <span class="fa-pull-right">{{ reg.name }}</span>
        </div>
        <div class="register-row">
          <span class="fa-pull-left">Register:</span>
          <span class="fa-pull-right">{{ reg.register }}</span>
        </div>
        <div class="register-row">
          <span class="fa-pull-left">Latest Reading:</span>
          <span class="fa-pull-right">{{ reg.value }} {{ reg.units }}</span>
        </div>
      </button>
      <div>
        <i class="bi bi-trash" @click="deleteRegister(reg)" title="Delete Register"></i>
      </div>
    </li>
  </ul>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'ReadRegisterList',
  setup() {
    const store = useStore();
    const readRegister = async function (value) {
      await store.dispatch('register/loadRegister', value);
    };

    const selectedReg = function (reg) {
      return store.state.register.address == reg;
    };

    const isRegToRead = function (reg) {
      return store.getters['register/registerToRead'] == reg;
    };

    const setRegToRead = function (reg) {
      store.commit('register/setRegisterToRead', { address: reg });
    };

    const deleteRegister = async function (reg) {
      if (confirm('You will lose all data associated with this register. Are you sure you wish to delete it?')) {
        await store.dispatch('register/deleteRegister', reg);
      }
    };

    return {
      registers: computed(() => {
        let registers = store.state.sensor.reading_registers;
        if (!registers) return;

        // format for display
        let regList = [];
        for (let idx in registers) {
          let reg = registers[idx];
          regList.push({
            name: reg.attributes.find(x => x.attribute_name == 'register_name')?.attribute_value,
            value: reg.readings?.[0],
            units: reg.attributes.find(x => x.attribute_name == 'register_units')?.attribute_value,
            register: reg.register
          });
        }
        return regList;
      }),
      readRegister,
      selectedReg,
      isRegToRead,
      setRegToRead,
      deleteRegister,
    }
  }
}
</script>

<style scoped>
li {
  font-size: 10pt;
}
button {
  width: 100%;
  background-color: var(--color-bg-sec);
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}
.active {
  box-shadow: none!important;
  background-color: var(--btn-pressed);
  transition: background 0.5s ease, color 0.5s ease;
}

.register-row {
  display: flex;
  justify-content: space-between;
}

.bi-star-fill {
  color: var(--color-sec);
}

i.bi:hover {
  color: gray;
}

.bi-trash {
  cursor: pointer !important;
  padding: 0 0 0 12px !important;
}

.bi-trash:hover {
  color: red !important;
}
</style>
