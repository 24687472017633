<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useStore } from 'vuex';
import moment from 'moment';

// Set up the access token and initial map coordinates
const accessToken = 'pk.eyJ1IjoiYmVub2l0ZHVyZXQiLCJhIjoiY2w2OXhjeHMyMTZ0ajNpbzVhM2MwaHlteiJ9.4R_hyzrZFN7bZrix5X_Nnw';
const coordinates = [-96.22526430339026, 38.80948251839888];

// References for the map container, map instance, and markers
const mapContainer = ref(null);
const map = ref(null);
const markers = ref([]);

// Access the Vuex store to retrieve the gateway list
const store = useStore();
const gatewayList = computed(() => store.state.apgList.List.gateway_GPS_Location);

onMounted(() => {
  mapboxgl.accessToken = accessToken;

  // Initialize the Mapbox map
  map.value = new mapboxgl.Map({
    container: mapContainer.value,
    style: 'mapbox://styles/mapbox/navigation-day-v1',
    center: coordinates,
    zoom: 3,
  });

  // Add markers once the map has loaded
  map.value.on('load', () => {
    addMarkers();
    resizeMap(); // Resize the map to fix any layout issues
  });
});

const resizeMap = () => {
  if (map.value) map.value.resize();
};

// Watch `gatewayList` to add markers when the data updates
watch(gatewayList, () => {
  addMarkers();
});

// eslint-disable-next-line no-undef
defineExpose({ resizeMap });

// Function to add markers to the map with formatted last update times
const addMarkers = () => {
  // Remove any existing markers
  markers.value.forEach((marker) => marker.remove());
  markers.value = [];

  // Add a new marker for each gateway in the list
  gatewayList.value?.forEach((gateway) => {
    const formattedDate = gateway.lastupdate ? moment(gateway.lastupdate).format('MMM Do YY, h:mm:ss a') : '';

    // Use the custom element as the marker by passing it in the `element` option
    const marker = new mapboxgl.Marker()
        .setLngLat([gateway.longitude, gateway.latitude])
        .setPopup(new mapboxgl.Popup().setText(formattedDate))
        .addTo(map.value);

    markers.value.push(marker);
  });
};
</script>
