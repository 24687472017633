<template>
  <div class="grc-item" :class="statusClass(status)" ref="reference">
    <div class="grc-scrollable">
      <div class="grc-content">
        <div class="grc-field" style="min-width: 150px">
          <small class="d-block text-muted">Sensor</small>
          <select class="form-control text-center" v-model="selectedSensor" @change="changeSelectedSensor">
            <option v-for="sensor in sensorOptions" :key="sensor.sensor_id" :value="sensor">
              {{ sensor.label }}
            </option>
          </select>
        </div>

        <div class="grc-field" v-if="sensorType !== 3">
          <small class="d-block text-muted">Register Address</small>
          <select class="form-control text-center" v-model="selectedRegister" @change="setRegisterAddress">
            <option v-if="selectedSensor == null" value="" disabled>Select a Sensor</option>
            <option v-for="register in registerOptions" :key="register.address" :value="register">
              {{ register.address }}
            </option>
          </select>
        </div>

        <div class="grc-field" v-if="sensorType !== 3">
          <small class="d-block text-muted">Register Quantity</small>
          <input type="number" step="1" min="1" max="4" @change="setLength"
                 class="form-control text-center"
                 :value="(length ?? selectedRegister?.quantity) ?? ''"
                 disabled
          />
        </div>

        <div class="grc-field" v-if="sensorType === 3 || gatewayType === 1">
          <small class="d-block text-muted">Power Line</small>
          <input type="number" step="1" min="1" max="6" @change="setPowerLine" class="form-control text-center"
                 :value="power_line"/>
        </div>

        <div class="grc-field">
          <small class="d-block text-muted">Function</small>
          <select class="form-control text-center" :value="selectedFunction?.application_type ?? ''"
                  @change="setFunction">
            <option v-if="selectedSensor == null" value="" disabled selected>Select a Sensor</option>
            <option v-if="sensorType !== 3 && selectedSensor != null" value="03">3</option>
            <option v-if="sensorType !== 3 && selectedSensor != null" value="04">4</option>
            <option v-if="sensorType === 3 && selectedSensor != null" value="30">30</option>
          </select>
        </div>

        <div class="grc-field" style="flex-grow: 4">
          <small class="d-block text-muted">Function Description</small>
          <input type="text" class="form-control text-center"
                 :value="selectedFunction?.application_type_description ?? ''" disabled/>
        </div>
      </div>
    </div>

    <!-- Ellipsis Button for Menu -->
    <div class="grc-actions">
      <button @click="toggleMenu" class="ellipsis-button">⋮</button>
      <div v-if="showMenu" class="dropdown-menu">
        <span class="ellipsis-menu-item" @click="removeGCR">Delete</span>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";

  export default {
    props: {
      id: String,
      sensor_id: Number,
      sensor_register: String,
      length: Number,
      power_line :Number,
      _function:String,
    },

    setup(props) {
      const store = useStore();
      const showModal = () => {
        console.log('click modal')
        store.commit("apgList/showModal", {});
      };

      const setRegisterAddress = function() {
        store.commit('readingControl/setRegister', {
          controlId: props.id,
          register: selectedRegister.value
        });
      }

      const setLength = function(e) {
        store.commit('readingControl/setLength', {
          controlId: props.id,
          length: e.target.value
        });
      }

      const setPowerLine = function(e) {
        store.commit('readingControl/setPowerLine', {
          controlId: props.id,
          powerline: e.target.value
        });
      }

      const setFunction = function(e) {
        selectedFunction.value = functionOptions.value.find(f => f.application_type === e.target.value);
        store.commit('readingControl/setFunctionCode', {
          controlId: props.id,
          code: selectedFunction.value.application_type
        });
      }

      const removeGCR = function () {
        showMenu.value = false;
        store.commit('readingControl/removeGCR', props.id);
      };

      const statusClass = (status) => {
        if (status === 'existing') return;
        return status;
      };

      const sensorOptions = store.getters["MyView/getGateway"](store.state.gateway.id).nodes;
      const selectedSensor = ref(sensorOptions.find(sensor => sensor.id === props.sensor_id));
      const changeSelectedSensor = () => {
        store.commit('readingControl/setSensor', {
          controlId: props.id,
          sensor: selectedSensor.value
        });
      };
      const selectedRegister = ref(selectedSensor.value?.addresses.find(register => register.address == props.sensor_register ?? ''));
      const functionOptions = ref(store.state.readingControl.functions);
      const selectedFunction = ref(functionOptions.value?.find(f => f.application_type === props._function));

      const showMenu = ref(false);
      const toggleMenu = () => showMenu.value = !showMenu.value;

      return {
        showModal,
        status: computed(() => store.getters["readingControl/getGrcStatus"](props.id)),
        removeGCR,
        statusClass,
        sensorOptions,
        selectedSensor,
        changeSelectedSensor,
        gatewayType: computed(() => store.state.gateway.type.gateway_type_id),
        sensorType: computed(() => selectedSensor.value?.sensor_type_id),
        selectedRegister,
        setRegisterAddress,
        registerOptions: computed(() => selectedSensor.value?.addresses),
        setLength,
        setPowerLine,
        showMenu,
        toggleMenu,
        setFunction,
        selectedFunction,
      };
    },
  };
</script>

<style scoped>
  li {
    cursor: pointer;
  }
  li:hover {
    background-color: antiquewhite;
  }

  div.new  {
    border: 3px solid #41b883;
  }

  div.updated {
    border: 3px solid orange;
  }

  div.deleted {
    text-decoration: line-through;
    color: var(--color-txt);
    opacity: 0.5;
    pointer-events: none;
  }

  .grc-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: var(--color-bg);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background 0.5s ease, color 0.5s ease;
    overflow-y: visible;
    position: relative;
    white-space: nowrap;
  }

  .grc-scrollable {
    overflow-x: auto;
    flex-grow: 1;
  }

  .grc-content {
    display: flex;
    align-items: center;
  }

  .grc-content > * {
    flex-shrink: 0;
    margin-left: 12px;
  }

  .grc-field {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: fit-content;
  }

  .grc-field > select, .grc-field > input {
    background: var(--color-bg-sec);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    color: var(--color-txt);
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
  }

  small {
    margin: 0!important;
    padding: 0!important;
    font-size: 0.75em;
    color: var(--color-txt);
    white-space: nowrap;
  }

  .text-muted {
    color: var(--color-txt)!important;
  }

  .grc-actions {
    position: relative;
    flex-shrink: 0;
  }

  .ellipsis-menu-item {
    cursor: pointer;
    padding: 4px;
    color: var(--color-txt);
  }

  .ellipsis-menu-item:hover {
    color: var(--color-sec);
  }

  .ellipsis-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding-left: 8px;
    color: var(--color-txt);
  }

  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: var(--color-bg-sec);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    z-index: 1000;
    min-width: 75px;
    white-space: nowrap;
    display: block;
  }
</style>