<template>
  <div class="modal-overlay" id="setup-wizard-modal">
  <div class="modal-content">
    <div class="modal-header">
      <h2>Gateway Configuration</h2>
      <button class="close-button" @click="close" aria-label="Close Modal">&times;</button>
    </div>
    <div class="modal-body">

      <!-- Step 1 -->
      <div class="wizard-step" :class="step===1? 'active': ''">
        <div class="step-title">
          <h3>Step 1: Gateway Name</h3>
        </div>
        <!-- Validation Message for Step 1 -->
        <div v-if="validationMessages[step-1]" class="validation-message">
          {{ validationMessages[step-1] }}
        </div>
        <div class="step-intro">
          <p>Welcome to the Gateway Configuration wizard. Let's start by setting the Gateway's name:</p>
        </div>
        <div class="step-input">
          <GatewayAttributeItem
              :value="findGatewayAttribute(9164)?.attribute_value"
              :keyName="'Gateway Name'"
              :disabled="false"
              :type="'text'"
              :attInfoId="9164"
              :ownerId="gatewayId"
          />
        </div>
        <div class="step-instructions">
          <p>Click <strong>Next</strong> to continue.</p>
        </div>
      </div>

      <!-- Sensor Definitions -->
      <div class="wizard-step" :class="step===2 && !waiting? 'active': ''">
        <div class="step-title">
          <h3>Step {{ step }}: Add Sensors</h3>
        </div>
        <!-- Validation Message for Step 1 -->
        <div v-if="validationMessages[step-1]" class="validation-message">
          {{ validationMessages[step-1] }}
        </div>
        <div class="step-intro d-flex align-items-center justify-content-between">
          <p>Define your sensors for this Gateway.</p>
          <button class="btn ms-2" id="addSensorBtn" @click="addSensor">
            <i class="bi bi-plus" style="font-size:1.1em"/> Add Sensor
          </button>
        </div>

        <!-- Dynamic List of Sensors -->
        <div class="step-input">
          <SensorList ref="sensorList"/>
        </div>

        <div class="step-instructions mt-4">
          Once you have finished adding sensors, click <strong>Next</strong> to configure the Gateway timings.
        </div>
      </div>

      <!--  Wait Step  -->
      <div class="wizard-step" :class="step===2 && waiting? 'active': ''">
          <div class="step-title">
            <h3>Initial Gateway Setup</h3>
          </div>
          <div class="step-input">
            <WaitComponent
                ref="waitStep"
                heading="Please wait while we configure your Gateway"
                :messages="['Adding sensors...', 'Creating reading controls...', 'Finishing up...']"
                :rotate="false"
            />
          </div>
        </div>

      <!--  Gateway Timings  -->
      <div class="wizard-step" :class="step===3? 'active': ''">
        <div class="step-title">
          <h3>Step {{ step }}: Gateway Timings </h3>
        </div>
        <!-- Validation Message -->
        <div v-if="validationMessages[step-1]" class="validation-message">
          {{ validationMessages[step-1] }}
        </div>
        <div class="step-intro">
          <p>Now that we have defined the sensors for this gateway, we need to configure the timing intervals.</p>
        </div>
        <div class="step-input" style="padding-bottom: 25px">
          <GatewayTimingTable @timingValidation="validateTimings"/>
        </div>
        <div class="step-instructions">
          <p>Click <strong>Next</strong> to continue.</p>
        </div>
      </div>

      <!--      Gateway Alarms     -->
      <div class="wizard-step" :class="step===4? 'active': ''">
        <div class="step-title">
          <h3>Step {{ step }}: Gateway Alarms </h3>
        </div>
        <!-- Validation Message -->
        <div v-if="validationMessages[step-1]" class="validation-message">
          {{ validationMessages[step-1] }}
        </div>
        <div class="step-intro">
          <p>Now that we have defined the sensors for this gateway, we need to configure the timing intervals.</p>
        </div>
        <div class="step-input">
          <AlarmList/>
        </div>
        <div class="step-instructions">
          <p>Click <strong>Next</strong> to continue.</p>
        </div>
      </div>

      <!--   Gateway Location    -->
      <div class="wizard-step" :class="step===5? 'active': ''">
        <div class="step-title">
          <h3>Step {{ step }}: Gateway Location</h3>
        </div>
        <!-- Validation Message -->
        <div v-if="validationMessages[step-1]" class="validation-message">
          {{ validationMessages[step-1] }}
        </div>
        <div class="step-intro">
          <p>(optional)</p>
        </div>
        <div class="step-input">
          <GatewayLocation ref="mapComponent"/>
        </div>
        <div class="step-instructions">
          <p>Click <strong>Next</strong> to continue.</p>
        </div>
      </div>

    <!-- Final Step -->
    <div class="wizard-step" :class="step === 6? 'active': ''">
      <h3>Finish</h3>
      <p>You are all set! Click <strong>Finish</strong> to complete the setup.</p>
    </div>
  </div>

    <!-- Modal Footer -->
    <div class="modal-footer sticky-bottom">
      <button class="button" :disabled="!canGoBack" @click="backStep">Back</button>
      <button class="button" v-if="!isFinalStep" :disabled="!canGoNext" @click="nextStep">Next</button>
      <button class="button" v-else @click="finishWizard">Finish</button>
    </div>
  </div>
  </div>
</template>


<script setup>
import { useStore } from 'vuex';
import GatewayAttributeItem from "@/components/shared/lists/gateway-attribute-item.vue";
import {computed, nextTick, ref, watch} from "vue";
import GatewayTimingTable from "@/components/shared/lists/gatewayTimingTable.vue";
import AlarmList from "@/components/shared/lists/alarm-list.vue";
import GatewayLocation from "@/components/shared/charts/GatewayLocation.vue";
import SensorList from "@/components/shared/lists/sensor-list.vue";
import WaitComponent from "@/components/shared/WaitComponent.vue";
import {useToast} from "vue-toastification";

const store = useStore();
const toast = useToast();
const sensorList = ref(null);
const waitStep = ref(null);
const waiting = ref(false);

// Define your validation logic per step
const getValidationStep = (stepNumber) => {
  switch (stepNumber) {
    case 1:
      return async () => ({
        msg: 'Please enter a name for the Gateway.',
        valid: findGatewayAttribute.value(9164)?.attribute_value != null && findGatewayAttribute.value(9164)?.attribute_value !== ''
      });
    case 2:
      return async () => {
        // validate
        await sensorList.value?.validate();

        // kick off the save and fetch
        waiting.value = true;
        waitStep.value?.start();
        const sensorSave = sensorList.value?.save();
        const sensorFetch = sensorList.value?.fetch();

        // save sensors
        let ok = await sensorSave;
        let msg = 'Sensors saved successfully';
        if (!ok) {
          close();
          toast.error('There was an error configuring your gateway. Please try again.');
        }

        // re-fetch sensors
        await sensorFetch;
        waitStep.value?.stop()

        waiting.value = false;
        return { msg: msg, valid: ok };
      };
    case 3:
      return async () => {
        if (validTimings) {
          store.dispatch('readingControl/sendGatewayTimings', {id: store.state.gateway.id});
        }
        return { msg: timingsMsg, valid: validTimings};
      };
    case 4:
      return async () => ({ msg: 'Please save alarms before continuing.', valid: !updatedAlarms.value });
    case 5:
      return async () => ({ msg: '', valid: await mapComponent.value?.saveCoordinates() || true });
    default:
      return async () => ({ msg: '', valid: true });
  }
};

let step = ref(1);
let totalSteps = 6;

const validationMessages = ref(Array(totalSteps).fill(''));
// Validation function for the current step
const validateStep = async () => {
  let stepValid = true;
  validationMessages.value[step.value - 1] = '';
  const validate = getValidationStep(step.value);
  const {valid, msg} = await validate();
  if (!valid) {
    validationMessages.value[step.value - 1] = msg;
    stepValid = false;
  }
  return stepValid;
};

// Validation function to check if all timing attributes meet criteria
let validTimings = true;
let timingsMsg = '';
const validateTimings = ({msg, valid}) => {
  validTimings = valid;
  timingsMsg = msg;
};

// Navigation functions
const nextStep = () => {
  validateStep().then((valid) => {
    if (valid) {
      step.value++;
    }
  });
};

const backStep = () => {
  if (step.value > 1) {
    step.value--;
  }
};

// Computed properties for button states
const canGoBack = computed(() => step.value > 1 && !waiting.value);
const canGoNext = computed(() => step.value < totalSteps && !waiting.value);
const isFinalStep = computed(() => step.value === totalSteps);

const finishWizard = async () => {
  await store.dispatch('gateway/sendMessage', {id: store.state.gateway.id});
  close();
};

const close = () => {
  step.value = 1;
  // refresh entire tree
  let gatewayId = store.state.gateway.id;
  store.commit('MyView/clearAll');
  // 'click' on the gateway that was just configured
  store.dispatch('MyView/click', {level: "gateway", id: gatewayId});
  // close the modal
  store.commit('ConfigWizard/setShowModal', false);
};

const gatewayId = computed(() => store.state.gateway.id);
const findGatewayAttribute = computed(() => (attr_info_id) => {
  let attrs = store.state.apgList.List.gateway_attribute;
  return attrs ? attrs.find(a => a.attribute_info_id === attr_info_id) : null;
});

// sensor list
const addSensor = () => store.commit('ConfigWizard/addBlankSensor');

// alarms
const updatedAlarms = computed(() => store.state.alarm.alarm_set.some(a => a.status !== 'existing'));

// location
const mapComponent = ref(null);
// Watch for the step to change to 5
watch(step, async (newStep) => {
  if (newStep != null && newStep === 5) {
    await nextTick(); // Wait for DOM updates
    mapComponent.value?.resizeMap(); // Call resizeMap method on the map component
  }
});
</script>

<style scoped>
option {
  background-color: var(--color-bg-sec);
}

input {
  width:100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--color-bg-sec);
  width: auto; /* Allows width to adjust based on content */
  max-width: 90vw; /* Limits modal width to viewport */
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: var(--color-txt);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 20px;
  background-color: var(--color-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
}

.modal-header h2 {
  margin: 0;
  font-size: 1.75em;
  color: var(--color-txt);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  color: var(--color-txt);
  cursor: pointer;
}

.close-button:hover {
  color: var(--color-sec);
}

.modal-body {
  overflow-y: auto;
  padding: 30px 20px;
  flex-grow: 1;
  line-height: 1.6;
}

.wizard-step {
  display: none;
  padding-bottom: 20px;
  overflow-y: auto;
}

.wizard-step.active {
  display: block;
}

.step-title h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.step-intro {
  margin-bottom: 20px;
}

.step-intro p {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.step-input {
  margin-bottom: 20px;
  max-height: 600px;
  overflow: auto;
}

@media (max-width: 700px) {
  .step-input {
    max-height: 40vh;
  }
}

.step-instructions {
  margin-top: 20px;
}

.modal-footer {
  padding: 15px 20px;
  background-color: var(--color-bg);
  border-top: 1px solid var(--color-border);
  text-align: right;
}

.button {
  padding: 12px 24px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  background-color: var(--active);
  color: var(--color-bg-sec);
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: var(--color-sec);
}

.button:disabled {
  background-color: var(--btn-pressed);
  cursor: not-allowed;
}

#finish-button {
  background-color: var(--color-sec);
}

#finish-button:disabled {
  background-color: var(--btn-pressed);
}

.validation-failure {
  border: 2px solid red;
}

.button-group {
  display: flex;
  gap: 10px;
}

.validation-message {
  color: red;
  font-size: 1em;
  margin-top: 5px;
}

#addSensorBtn {
  background-color: green;
  border-color: green;
}

#addSensorBtn:hover {
  background-color: darkgreen!important;
  border-color: darkgreen!important;
}
</style>
