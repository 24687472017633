<template>
  <div>
    <font-awesome-icon icon="file-export" @click="downloadExcelData" style="cursor: pointer" title="Export Data"/>
  </div>
</template>

<script>
import {useStore} from 'vuex'
import * as XLSX from 'xlsx';
import moment from 'moment';

export default {
  props: {
    outputFileName: String,
  },
  setup(props) {
    let store = useStore()

    function downloadExcelData() {
      // Assuming getVisibleData now returns an object of arrays, where each key is a sheet name.
      let dataObject = store.getters["chart/getVisibleData"];

      // Create a new workbook.
      const wb = XLSX.utils.book_new();

      // Loop over each property in the object and create a sheet for each.
      for (const sheetName in dataObject) {
        const sheetData = dataObject[sheetName];
        if (Array.isArray(sheetData) && sheetData.length) {
          // Process each object: remove the 'z' property if it's undefined.
          const processedData = sheetData.map(item => {
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty('z') && (item.z == null || Number.isNaN(item.z))) {
              // Create a shallow copy and remove z.
              const newItem = {...item};
              delete newItem.z;
              return newItem;
            }
            return item;
          });

          // Create a worksheet. The option `cellDates: true` ensures that Date objects
          // are written as Excel dates, and dateNF formats them with seconds.
          const ws = XLSX.utils.json_to_sheet(processedData, {
            cellDates: true,
            dateNF: "yyyy-mm-dd hh:mm:ss"
          });

          XLSX.utils.book_append_sheet(wb, ws, sheetName);
        }
      }

      // Get the current date with resolution to seconds.
      const curr_date = moment().format("YYYY-MM-DD__HH-mm-ss");
      const filename = `${curr_date}_${props.outputFileName}.xlsx`;
      // Write the workbook to a file and trigger the download.
      XLSX.writeFile(wb, filename);
    }

    return {
      downloadExcelData
    }
  }
}

</script>