<template>
  <div class="card" style="margin-top: 10px; height: 50vh; display: flex; flex-direction: column;">
    <div class="card-header">Alarm History</div>
    <div class="card-block table-responsive" style="flex: 1; overflow-y: auto;">
      <table class="table table-bordered text-nowrap">
        <tr class="sticky-top-z1">
          <th>Sensor</th>
          <th>Status</th>
          <th>Alarm Value</th>
          <th>Sensor Value</th>
          <th>Triggered On</th>
          <th>Received On</th>
          <th>Acknowledged On</th>
          <th>Acknowledged By</th>
        </tr>
        <tbody>
        <tr v-for="(alarm) in alarms" :key="alarm" :class="{ 'alarm-row': alarm.status == 'Active'}">
          <td style="text-align: center">{{ getSensorLabel(alarm.sensor_id) }}</td>
          <td style="text-align: center">{{ alarm.status }}</td>
          <td style="text-align: center">{{ alarm.alarm_set_point }}</td>
          <td style="text-align: center">{{ alarm.sensor_value }}</td>
          <td style="text-align: center">{{ moment(alarm.trigger_dt) }}</td>
          <td style="text-align: center">{{ moment(alarm.received_dt) }}</td>
          <td v-if="alarm.status === 'Active'" style="text-align: center" colspan="2">
            <button
                class="form-control"
                @click="AcknowledgeAlarm(alarm.alarm_data_id)"
                title="Acknowledge Alarm"
                id="acknowledgeBtn"
            >
              <font-awesome-icon icon="check" />
            </button>
          </td>
          <template v-else>
            <td style="text-align: center">{{ moment(alarm.cleared_dt) }}</td>
            <td style="text-align: center">{{ alarm.cleared_by_user }}</td>
          </template>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
export default {
  
  components: {
  }, 
  methods: {
      moment: function (date) {
        if (date != null) {
          return moment(date).format('MMM Do YY, h:mm:ss a');
        } else  {
          return '';
        }
      }
    },
  setup() {
    let store = useStore();
    const AcknowledgeAlarm = alarm_data_id => store.dispatch("alarm/AcknowledgeAlarm", {alarm_data_id:alarm_data_id});
    const getSensorLabel = sensor_id => store.getters["MyView/getGateway"](store.state.gateway.id).nodes.find(s => s.id == sensor_id)?.label;
    return {
      alarms: computed(() => store.state.alarm.alarm_history?.filter(a => a.sensor_id == store.state.sensor.id)),
      AcknowledgeAlarm,
      getSensorLabel,
    }
  }
}
</script>

<style scoped>
.alarm-row > td {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  0%, 100% {
    background-color: var(--color-bg-sec);
  }
  25% {
    background-color: red;
  }
}

#acknowledgeBtn {
  color: var(--color-txt);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

#acknowledgeBtn:hover {
  background-color: var(--color-sec);
  border-color: var(--color-sec);
}
</style>
