<template>
  <div class="wait-component-container">
    <!-- Title / Heading -->
    <h3>{{ heading }}</h3>

    <!-- Show the spinner while 'isRotating' is true (and user wants a spinner) -->
    <div
        v-if="showSpinner && isRotating"
        class="spinner-container"
    >
      <i class="bi bi-arrow-repeat spinner-icon"></i>
    </div>

    <!-- The message currently being displayed -->
    <div class="message-text">
      {{ currentMessage }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted, defineProps, defineExpose } from 'vue'

/**
 * Props:
 *  - heading:        A title or heading string displayed at the top
 *  - messages:       An array of strings to rotate through
 *  - rotate:         If true, loop the messages indefinitely
 *                    If false, show them only once in order, then stop
 *  - rotateInterval: Milliseconds between switching to the next message
 *  - showSpinner:    Toggle whether to display a spinner while rotating
 */
const props = defineProps({
  heading: {
    type: String,
    default: 'Please wait while we process your request...'
  },
  messages: {
    type: Array,
    default: () => [
      'Gathering sensor data…',
      'Setting up Modbus registers…',
      'Double-checking alignment…',
      'One moment please…'
    ]
  },
  rotate: {
    type: Boolean,
    default: true
  },
  rotateInterval: {
    type: Number,
    default: 5000
  },
  showSpinner: {
    type: Boolean,
    default: true
  }
})

/** Internal state */
const currentIndex = ref(0)         // which message in the array is shown
const finalMessage = ref('')        // message to display when rotation stops
const useFinalMessage = ref(false)  // determines if we show finalMessage
const isRotating = ref(false)       // controls spinner visibility & rotation

let rotationTimer = null

/**
 * Start rotating (or single-pass) from the beginning.
 * - Resets any previous final messages
 * - Shows spinner (if showSpinner = true)
 * - Rotates messages continuously (rotate=true) or one-pass (rotate=false)
 */
function start() {
  // Clear any previous rotation
  stopRotation()

  // Reset internal state
  currentIndex.value = 0
  finalMessage.value = ''
  useFinalMessage.value = false
  isRotating.value = true

  // If there's only 1 or 0 messages, there's no rotation to do
  if (props.messages.length <= 1) {
    return
  }

  // Otherwise, start rotating on a timer
  rotationTimer = setInterval(() => {
    if (props.rotate) {
      // Continuous loop
      currentIndex.value = (currentIndex.value + 1) % props.messages.length
    } else {
      // Single pass: increment until the last message
      if (currentIndex.value < props.messages.length - 1) {
        currentIndex.value++
      } else {
        isRotating.value = false
      }
    }
  }, props.rotateInterval)
}

/**
 * Stop rotation immediately and display the given message indefinitely.
 * Also turns off the spinner.
 */
function stop(message) {
  // clean up
  stopRotation()

  finalMessage.value = message
  useFinalMessage.value = true
  isRotating.value = false
}

/** Utility: clears the rotation timer, if any */
function stopRotation() {
  if (rotationTimer) {
    clearInterval(rotationTimer)
    rotationTimer = null
  }
}

onUnmounted(() => {
  stopRotation()
})

/**
 * Computed:
 * - If a final message has been set (via stop()), show that
 * - Otherwise, show the current rotating message
 * - Fallback if there are no messages
 */
const currentMessage = computed(() => {
  if (useFinalMessage.value) {
    return finalMessage.value
  }
  if (props.messages.length === 0) {
    return 'Working...'
  }
  return props.messages[currentIndex.value]
})

/** Expose the start/stop methods so parent can call e.g.:
 *  - waitComponentRef.value?.start()
 *  - waitComponentRef.value?.stop("All done!")
 */
defineExpose({
  start,
  stop
})
</script>

<style scoped>
.wait-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
}

.spinner-container {
  margin: 1rem 0;
  font-size: 2rem;
  animation: spin 1s linear infinite;
}

.spinner-icon {
  /* Or rotate the icon itself:
  animation: spin 1.2s linear infinite;
  */
}

.message-text {
  margin-top: 1rem;
  font-size: 1.1rem;
  max-width: 600px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
