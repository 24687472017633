import axios from 'axios';
import { useToast } from 'vue-toastification';

const toast = useToast();

export const ConfigWizard  = {
  namespaced: true,
  state: () => ({
    showModal: false,
    sensors: [],
    blankAttributes: [],
    sensorModels: [],
    gatewayType: null,
  }),

  mutations: {
    setShowModal(state, bool) {
      state.sensors = [];
      if (bool) {
        this.dispatch('ConfigWizard/fetchSensorDefinitions');
      }
      state.showModal = bool;
    },
    setSensors(state, sensors) {
      state.sensors = sensors;
    },
    setSensor(state, { sensor }) {
      const sensorIndex = state.sensors.findIndex(s => s.sensor_id === sensor.sensor_id);
      if (sensorIndex !== -1) {
        state.sensors[sensorIndex] = sensor;
      } else {
        state.sensors.push(sensor);
      }
    },
    setBlankAttributes(state, attributes) {
      let sortOrder = [9151, 9150, 9153, 9146];
      attributes.sort((a, b) => sortOrder.indexOf(a.attribute_info_id) - sortOrder.indexOf(b.attribute_info_id));
      state.blankAttributes = attributes;
    },
    setSensorModels(state, models) {
      state.sensorModels = models;
    },
    addBlankSensor(state) {
      state.sensors.push({
        sensor_id: null,
        attributes: JSON.parse(JSON.stringify(state.blankAttributes)),
        registers: [],
        collapsed: true,
      });
    },
    updateSensorRegisters(state, { sensorIdx, registers }) {
      state.sensors[sensorIdx].registers = {
        ...state.sensors[sensorIdx].registers,
        ...registers
      };
    },
    setSensorRegister(state, { sensorId, register }) {
      let sensor = state.sensors.find(s => s.sensor_id === sensorId);
      if (!sensor) return;

      let registerIndex = sensor.registers.findIndex(r => r.address === register.address);
      if (registerIndex !== -1) {
        sensor.registers[registerIndex] = register;
      } else {
        sensor.registers.push(register);
      }
    },
    updateSensorType(state, { sensorIdx, type_id }) {
      let sensorTypeAttr = state.sensors[sensorIdx].attributes.find(attr => attr.attribute_info_id === 9151);
      if (sensorTypeAttr != null)  {
        sensorTypeAttr.attribute_value = type_id;
      } else {
        // Add sensor type attribute if it doesn't exist
        let blank = state.blankAttributes.find(attr => attr.attribute_info_id === 9151);
        state.sensors[sensorIdx].attributes.push({
          ...blank,
          attribute_value: type_id,
        });
      }
      // overwrite state.sensors with model info
      let model = JSON.parse(JSON.stringify(state.sensorModels.find(m => m.sensor_model_id == type_id)));
      state.sensors[sensorIdx] = {
        ...state.sensors[sensorIdx],
        ...model,
      };
    },
    toggleCollapse(state, idx) {
      state.sensors[idx].collapsed = !state.sensors[idx].collapsed;
    },
    deleteSensor(state, idx) {
      state.sensors[idx].deleted = true;
    },
    setGatewayType(state, type) {
      state.gatewayType = type;
    },
  },

  actions: {
    async fetchSensorDefinitions({rootState, commit}) {
      try {
        let gatewayId = rootState.gateway.id;
        let url = `${rootState.settings.api_base}gateways/wizardSensorAttributes/${gatewayId}`;
        let response = await axios.get(url);
        if (response?.status === 200) {
          let {empty, sensorModels, gatewayType, ...sensors} = response.data;
          Object.keys(sensors).forEach(key => {
            response.data[key].collapsed = true;
            let sortOrder = [9151, 9150, 9153, 9146];
            response.data[key].attributes.sort((a, b) => sortOrder.indexOf(a.attribute_info_id) - sortOrder.indexOf(b.attribute_info_id));
          });
          // filter out deleted sensors and registers
          sensors = Object.values(sensors).map(s => {
            s.registers = s.registers.filter(r => !r.deleted);
            return s;
          }).filter(s => !s.deleted);
          commit('setSensors', sensors);
          commit('setBlankAttributes', empty);
          commit('setSensorModels', sensorModels);
          commit('setGatewayType', gatewayType);
        } else {
          console.warn(`No sensor definitions were fetched: ${response?.status}`);
        }
      } catch (e) {
        console.error(`Could not fetch sensor definitions: ${e}`);
      }
    },

    async saveSensors({rootState, state}) {
      try {
        let data = state.sensors;
        let url = `${rootState.settings.api_base}sensors/saveAll/${rootState.gateway.id}`;
        let response = await axios.post(url, {
          sensors: data
        });
        if (response?.status === 200) {
          toast.success('Sensors saved successfully');
          return true;
        } else {
          toast.error('Could not save sensors. Please try again later.');
          return false;
        }
      } catch (e) {
        console.error(`Could not save sensors: ${e}`);
        toast.error('Could not save sensors. Please try again later.');
        return false;
      }
    },
  },

  getters: {
    showModal: state => state.showModal,
    getSensor: state => idx => state.sensors[idx],
    isDeleted: state => idx => state.sensors[idx]?.deleted,
    registersForModel: state => sensorModel => {
      const model = state.sensorModels.find(m => m.sensor_model_id == sensorModel);
      return model ? model.registers.slice().sort((a, b) => a.address - b.address) : [];
    },
    selectedRegisters: state => sensorIdx => {
      return state.sensors[sensorIdx]?.registers?.filter(r => r.address != null && !r.deleted) || [];
    },
    isModbus: (state, getters) => sensorIdx => getters.sensorProtocolTypeId( sensorIdx) === 1,
    sensorModelTypeId: (state, getters) => sensorIdx => getters.getAttribute(sensorIdx, 9151)?.attribute_value,
    sensorProtocolTypeId: (state, getters) => sensorIdx => {
      let modelId = getters.sensorModelTypeId(sensorIdx);
      let model = state.sensorModels.find(m => m.sensor_model_id == modelId);
      return model?.sensor_type_id;
    },
    isPowerLine: (state, getters) => sensorIdx => {
      return getters.sensorProtocolTypeId(sensorIdx) == 3 || state.gatewayType.gateway_type_name.toLowerCase() === 'swarm';
    },
    isOther: (state, getters) => sensorIdx => getters.sensorModelTypeId(sensorIdx) == 1,
    getAttribute: state => (sensorIdx, attrInfoId) => {
      let existingAttr = state.sensors[sensorIdx]?.attributes?.find(attr => attr.attribute_info_id === attrInfoId);
      if (existingAttr) {
        return existingAttr;
      }
      let blankAttr = state.blankAttributes.find(attr => attr.attribute_info_id === attrInfoId);
      return blankAttr ? JSON.parse(JSON.stringify(blankAttr)) : null;
    },
  }
}
