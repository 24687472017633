import axios from "axios";
import {useToast} from 'vue-toastification';
const toast = useToast();


export const readingControl = {
  namespaced: true,
  state: {
    controls: [],
    timings: {
      hiFreq: null,
      commFreq: null,
      bootup: null,
    },
    functions: null,
    emptyControl: {
      application_type: '',
      application_type_description: null,
      control_id: null,
      length: null,
      power_line: null,
      sensor_address: null,
      sensor_id: null,
      sensor_register: null,
      status: 'new',
    },
    originalControls: [],
    originalTimings: {
      hiFreq: null,
      commFreq: null,
      bootup: null,
    },
    abortController: null,
  },

  mutations: {
    loadControls(state, controls) {
      state.controls = controls;
      state.originalControls = JSON.parse(JSON.stringify(controls));
    },
    loadTimings(state, {timings, rootState}) {
      let gatewayType = rootState.gateway.type.gateway_type_id;
      timings.forEach(t => {
        // add 'existing' status to each timing and set each timing
        t.status = 'existing';
        switch (t.attribute_info_id){
          case 9167:
            state.timings.bootup = gatewayType === 1 ? t : null;
            break;
          case 9149:
            state.timings.commFreq = t;
            break;
          case 9148:
            state.timings.hiFreq = t;
            break;
        }
      });
      // save original
      state.originalTimings = JSON.parse(JSON.stringify({
        hiFreq: state.timings.hiFreq,
        commFreq: state.timings.commFreq,
        bootup: state.timings.bootup ?? null,
      }));
    },
    setSensor(state, { controlId, sensor }) {
      state.controls.find(c => c.control_id === controlId).sensor_id = sensor.id;
      this.commit('readingControl/updateControlStatus', {controlId, status: 'updated'});
    },
    setRegister(state, { controlId, register }) {
      let control = state.controls.find(c => c.control_id === controlId);
      control.sensor_register = register.address.toString()
      control.length = register.quantity;
      this.commit('readingControl/updateControlStatus', {controlId, status: 'updated'});
    },
    setLength(state, { controlId, length }) {
      state.controls.find(c => c.control_id === controlId).length = parseInt(length);
      this.commit('readingControl/updateControlStatus', {controlId, status: 'updated'});
    },
    setPowerLine(state, {controlId, powerline}) {
      state.controls.find(c => c.control_id === controlId).power_line = parseInt(powerline);
    },
    setFunctionCode(state, { controlId, code }) {
      state.controls.find(c => c.control_id === controlId).application_type = code;
      this.commit('readingControl/updateControlStatus', {controlId, status: 'updated'});
    },
    setHiFreq(state, freq) {
      state.timings.hiFreq.attribute_value = freq;
      this.commit('readingControl/updateTimingStatus', {timingName: 'hiFreq', status: 'updated'});
    },
    setCommFreq(state, freq) {
      state.timings.commFreq.attribute_value = freq;
      this.commit('readingControl/updateTimingStatus', {timingName: 'commFreq', status: 'updated'});
    },
    setBootup(state, bootup) {
      state.timings.bootup.attribute_value = bootup;
      this.commit('readingControl/updateTimingStatus', {timingName: 'bootup', status: 'updated'});
    },
    updateControlStatus(state, {controlId, status}) {
      let control = state.controls.find(c => c.control_id === controlId);
      if (status === 'deleted') {
        control.status = 'deleted';
        return;
      }
      if (control.status === 'new') {
        return;
      }
      // Check if control has changed
      // eslint-disable-next-line no-unused-vars
      let {status: controlStatus, ...controlNoStatus} = control;
      // eslint-disable-next-line no-unused-vars
      let {status: originalStatus, ...originalControl} = state.originalControls.find(c => c.control_id === controlId);
      if (JSON.stringify(controlNoStatus) === JSON.stringify(originalControl)) {
        control.status = 'existing';
      } else {
        control.status = status;
      }
    },
    updateTimingStatus(state, {timingName, status}) {
      let timing = state.timings[timingName];
      if (status === 'deleted') {
        timing.status = 'deleted';
        return;
      }
      if (timing.status === 'new') {
        return;
      }
      // Check if timing has changed
      // eslint-disable-next-line no-unused-vars
      let {status: timingStatus, ...timingNoStatus} = timing;
      // eslint-disable-next-line no-unused-vars
      let {status: originalStatus, ...originalTiming} = state.originalTimings[timingName];
      if (JSON.stringify(timingNoStatus) === JSON.stringify(originalTiming)) {
        timing.status = 'existing';
      } else {
        timing.status = status;
      }
    },
    addNewGCR(state) {
      let currentIds = state.controls.map(c => c.control_id);
      let tempId;
      do {
        tempId = Math.floor(Math.random() * 100000);
      } while (currentIds.includes(tempId));

      let emptyControl = JSON.parse(JSON.stringify(state.emptyControl));
      emptyControl.control_id = (-tempId).toString();
      state.controls.push(emptyControl);
    },
    removeGCR(state, controlId) {
      let control = state.controls.find(c => c.control_id === controlId);
      control.status = 'deleted';
    }
  },

  actions: {
    async findGatewayReadingControl({rootState, commit, getters}, payload) {
      let id = payload.id;
      console.log('find all gateways Reading control for ', id);
      let url = `${rootState.settings.api_base}gateways/readingControl/${id}`;
      try {
        let rs = await axios.get(url, {
          signal: getters.getAbortController.signal,
        });
        console.log('found gateways reading control ', rs.data);
        commit('loadControls', rs.data);
      } catch (err) {
        console.error('Failed to load gateway reading control.  Error was ');
        console.error({err});
      }
    },

    async sendGatewayReadingControl({rootState, getters, state, dispatch}) {
      try {
        let url = `${rootState.settings.api_base}gateways/updateGCR`;

        // quick check to see if the controls have changed
        if (!getters.areControlsChanged) {
          console.warn('No changes to GCR');
          return;
        }

        let rs = await axios.patch(url, {
          gatewayId: rootState.gateway.id,
          data: state.controls,
        });
        console.log('result update GCR = ', rs.data);
        let payload = {id: rootState.gateway.id};
        toast.success('Queued GRC messages!');
        await this.dispatch('gateway/findQueuedMessages', payload);
        await dispatch('findGatewayReadingControl', payload);
      } catch (err) {
        console.error('Failed to update GCR ', err);
        toast.error('Unable to queue GRC messages. Please try again later.');
      }
    },

    async findGatewayTimings({rootState, commit, getters}, payload) {
      let url = `${rootState.settings.api_base}gateways/timings/${payload.id}`;
      try {
        let rs = await axios.get(url, {
          signal: getters.getAbortController.signal,
        });
        commit('loadTimings', {timings: rs.data, rootState});
      } catch (error) {
        console.error('findGatewayTimings: error was: ', error);
      }
    },

    async sendGatewayTimings({rootState, state, dispatch}) {
      let payload = {id: rootState.gateway.id};
      let url = `${rootState.settings.api_base}gateways/timings/${payload.id}`;
      console.log('url', url);
      // compile data
      let user_id = rootState.auth.user.user.Auth.system_user_id;
      let data = [];
      // loop through the timings
      for (let k of Object.keys(state.timings)) {
        let attr = state.timings[k];
        // only grab the timings that have changed
        if (attr?.status != null && attr?.status === 'updated') {
          data.push({
            attr_info_id: attr.attribute_info_id,
            user_id: user_id,
            attr_name: attr.attribute_name,
            attr_value: attr.attribute_value,
            pretty_name: attr.attribute_pretty_name,
          });
        }
      }
      if (data.length === 0) {
        console.warn('No timings to update');
        return;
      }

      try {
        await axios.post(url, {data: data});
        toast.success('Timings updated!');
        await dispatch('findGatewayTimings', payload);
        await this.dispatch('gateway/findQueuedMessages', payload);
      } catch (error) {
        console.error('sendGatewayTimings: error was: ', error);
      }
    },

    async getFunctions({rootState, state}) {
      let url = `${rootState.settings.api_base}general/functions`;
      try {
        let rs = await axios.get(url);
        state.functions = rs.data;
      } catch (e) {
        console.error(`readingControl.getFunctions: ${e}`);
      }
    }
  },

  getters: {
    getGrcStatus: state => id => state.controls.find(c => c.control_id === id)?.status,
    getAbortController: (state) => {
      if (!state.abortController) {
        state.abortController = new AbortController();
      }
      return state.abortController;
    },
    areTimingsChanged: state => {
      for (let k of Object.keys(state.timings)) {
        if (state.timings[k] != null && state.timings[k]?.status !== 'existing') {
          return true;
        }
      }
      return false;
    },
    areControlsChanged: state => {
      for (let c of state.controls) {
        if (c.status != null && c.status !== 'existing') {
          return true;
        }
      }
      return false;
    },
  }
}
