<template>
  
  <tr :class="{ 'table-danger': changed }">
    <td> {{ address2? `${address} & ${address2}` : address }}</td>

    <td>{{ description }}</td>
    <td>
      <input class="form-control" type="number" @input="change" :min="min" :max="max" :value="value"/>
    </td>
    <td v-if="LUTS!=null"> {{ moment(LUTS) }} </td>
    <td v-if="LUTS==null"> </td>
    <td v-if="LUU == 0"> Sensor </td>
    <td v-if="LUU && LUU != 0"> User </td>
    <td v-if="!LUU && LUU != 0"> </td>
  </tr>
</template>

<script>
  import { useStore } from "vuex";
  import moment from 'moment'
  import { computed } from "vue";

  export default {
    props: {
      idx: Number,
      array_idx: Number,
      address: Number,
      description : String,
      register_type: String,
      holding_registers_id: Number, 
      min :Number, 
      max :Number, 
      sensor_model: String,
      value: Number,
      LUTS: Date,
      origin: String,
      LUU: Number
    },
    data() {
      return {
        address2: null
      }
    },
    mounted() {
      this.checkAndEmitAddress2();
    },
    setup(props) {
      const store = useStore();
      let parametersChanged =false;

      const showModal = () => {
        store.commit("apgList/showModal", {});
      };

      const change = function (e) {
        parametersChanged = true;
        store.commit('sensor/setHoldingRegisterValue', {
          address: props.address,
          value: e.target.value
        });
      };

      return {
        showModal,
        change,
        parametersChanged,
        changed: computed(() => store.getters['sensor/individualHrChanged'](props.array_idx)),
      };
    },
    methods: {
      moment: function (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      },
      checkAndEmitAddress2: function () {
        if (['U32', 'I32', 'FLOAT', 'Reverse FLOAT'].includes(this.register_type)) {
          this.address2 = this.address + 1;
        }
      }
    }
  };
</script>

<style scoped>
  .table-danger {
    color: var(--color-txt);
    background-color: var(--color-sec);
  }
  .table-danger td input {
    background-color: var(--color-bg-sec);
    color: var(--color-txt);
  }
</style>